import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import TechnicalSheetVersionsCarousel from '../TechnicalSheetVersionsCarousel';
import StandardTechnicalSheet from '../StandardTechnicalSheet';
import { getVersionSlug } from '%/utils/urlHandler';

export default class OffersTechnicalSheet extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeVersionIndex: 0,
      activeDatasheet: [],
    }

    this.formattedDatasheet = this.formattedDatasheet.bind(this);
    this.handleVersionChange = this.handleVersionChange.bind(this);

    this.getVersionFromURL();
  }

  getVersionFromURL() {
    const versionSlug = getVersionSlug();
    if (!versionSlug) return

    const activeVersion = this.findVersionIndexBySlug(versionSlug)
    this.updateVersion(activeVersion)
  }

  findVersionIndexBySlug(slug) {
    const version = this.props.versions.find(version => version.slug === slug);
    return this.props.versions.indexOf(version);
  }

  updateVersion(versionIndex) {
    this.setState({
      activeVersionIndex: versionIndex,
    })
  }

  handleVersionChange(versionIndex) {
    this.setState({ activeVersionIndex: versionIndex })
  }

  formattedDatasheet() {
    const datasheet = this.props.versions[this.state.activeVersionIndex];
    if (!datasheet) return;

    const formattedDatasheet = {
      detail_list: datasheet.detail_list,
      comfort_list: datasheet.comfort_list,
      connectivity_list: datasheet.connectivity_list,
      security_list: datasheet.item_list
    }

    return formattedDatasheet;
  }

  render() {
    return (
      <div>
        {
          this.props.versions && (
            <TechnicalSheetVersionsCarousel
              handleVersionChange={this.handleVersionChange}
              activeVersionIndex={this.state.activeVersionIndex}
              versions={this.props.versions}
              showVersionInURL={this.props.showVersionInURL}
              showPrice={this.props.showPrice}
            />
          )
        }
        <StandardTechnicalSheet
          datasheet={this.formattedDatasheet()}
          id="#offer-techinical-sheet"
        />
      </div>
    )
  }
}

OffersTechnicalSheet.propTypes = {
  versions: PropTypes.array,
  showVersionInURL: PropTypes.bool,
  showPrice: PropTypes.bool,
}
