/* eslint-disable react/no-unused-prop-types */
import { Component } from 'react';
import PropTypes from 'prop-types';
import handleInputChange from '%/utils/handleInputChange';
import { getPayload } from '%/utils/forms/getPayload';
import validationFactory from '%/utils/validation';
import { mask } from '%/utils/formUtils/maks/mask';
import { setRecaptchaLoadOnFormFocus } from '%/utils/reCAPTCHA';
import { updateURLWithActiveVersion, getVersionSlug } from '%/utils/urlHandler';
import FormWrapper from '../FormComponents/FormWrapper';
import FormHeader from '../FormComponents/FormHeader';
import FormBody from '../FormComponents/FormBody';
import FieldGroup from '../FormComponents/FieldGroup';
import Input from '../FormComponents/Fields/Input';
import Checkbox from '../FormComponents/Fields/Checkbox';
import Select from '../FormComponents/Fields/Select';
import FormFooter from '../FormComponents/FormFooter';
import SubmitButton from '../FormComponents/SubmitButton';
import ContactFieldGroup from '../FormComponents/Fields/ContactFieldGroup';
import VehicleInExchangeFieldGroup from '../FormComponents/Fields/VehicleInExchangeFieldGroup';
import FormMessageOverlay from '../../FormMessageOverlay';

export default class NewVehicleFormApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      email: null,
      phone: null,
      cpf: '',
      unit: null,
      'veiculo-na-troca': null,
      mailing: null,
      whatsapping: null,
      phoning: null,
      activeVersion: null,
      selectedVariant: null,
      dataPermissions: false,
      formState: {
        isSubmitting: false,
        errorSending: false,
        showOverlay: false,
      },
    };

    this.handleInputChange = handleInputChange.bind(this);
    this.handleCloseOverlay = this.handleCloseOverlay.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVersionChange = this.handleVersionChange.bind(this);
    this.versionsToSelect = this.versionsToSelect.bind(this);
    this.setActiveVersion = this.setActiveVersion.bind(this);
    this.getVersionFromProps = this.getVersionFromProps.bind(this);

    this.setActiveVersion();
  }

  componentDidMount() {
    this.initializeForm();
  }

  handleVersionChange(event) {
    this.handleInputChange(event);

    this.setState(prevState => {
      const activeVersion = this.findVersionBySlug(prevState.selectedVariant);
      updateURLWithActiveVersion(activeVersion.slug);
      return { activeVersion };
    });
  }

  handleCloseOverlay() {
    this.setState({ formState: { showOverlay: false } });
  }

  handleSubmit(event) {
    event.preventDefault();

    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;
    if (!formIsValid) return;

    const payload = getPayload(this.state, this.props);

    this.setState({
      formState: { isSubmitting: true },
    });

    window.serviceConversion
      .convert(payload)
      .then(() => {
        this.setState({
          formState: {
            isSubmitting: false,
            errorSending: false,
          },
        });
        return {
          data: payload,
          status: 'success',
        };
      })
      .catch(error => {
        this.setState({
          formState: {
            isSubmitting: false,
            errorSending: true,
          },
        });
        console.error(error);
        return {
          data: error,
          status: 'error',
        };
      })
      .then(result => {
        document.dispatchEvent(
          new CustomEvent('new_conversion', {
            detail: {
              status: result.status,
              data: result.data,
            },
          }),
        );
        this.setState({
          formState: { showOverlay: true },
        });
      });
  }

  setActiveVersion() {
    const activeVersion = this.getVersionFromProps();

    if (!activeVersion) return;

    this.setState({
      activeVersion,
      selectedVariant: activeVersion.slug,
    });
  }

  getVersionFromProps() {
    const { versions, defaultVariant } = this.props;

    if (defaultVariant) return defaultVariant;

    return versions && versions[0];
  }

  getVersionFromURL() {
    const { defaultVariant } = this.props;
    if (defaultVariant) return;

    const versionSlug = getVersionSlug();
    if (!versionSlug) return;

    const activeVersion = this.findVersionBySlug(versionSlug);
    this.updateVersion(activeVersion);
  }

  watchStore() {
    window.store.vehicleVersionDetails.watch(state => {
      this.setState({
        activeVersion: state.activeVersion,
        selectedVariant: state.activeVersion.slug,
      });
    });
  }

  initializeForm() {
    if (this.phoneInput) {
      mask(this.phoneInput, { mask: 'phone' }, phoneMaskvalue =>
        this.setState({ phone: phoneMaskvalue }),
      );
    }

    if (this.cpfInput) {
      mask(this.cpfInput, { mask: 'cpf' }, cpfMaskValue =>
        this.setState({ cpf: cpfMaskValue }),
      );
    }

    this.validator = validationFactory(`#${this.props.formId}`);

    if (this.props.basedOnStore) {
      this.watchStore();

      return;
    }

    if (this.props.asynchronouslyLoaded) {
      setRecaptchaLoadOnFormFocus();
    }

    this.getVersionFromURL();
  }

  versionsToSelect() {
    return this.props.versions.map(version => ({
      label: version.name,
      value: version.slug,
      selected: version.slug === this.state.selectedVariant,
    }));
  }

  updateVersion(version) {
    if (!version) return;

    this.setState({
      activeVersion: version,
      selectedVariant: version.slug,
    });
  }

  findVersionBySlug(slug) {
    return this.props.versions.find(version => version.slug === slug);
  }

  render() {
    const {
      formId,
      showCpf,
      showUnits,
      showDataPermissions,
      showContactOptions,
      showVariantImage,
      dataPermissionsCustomText,
      linkPrivacyPolicy,
      showSelectVariant,
      versions,
      title,
      subtitle,
      buttonLabel,
      showActiveVersionPrice,
      showVehicleInExchange,
    } = this.props;
    const { activeVersion } = this.state;

    return (
      <FormWrapper
        customClasses="conversion-form--new-vehicle"
        formId={formId}
        setRef={form => {
          this.form = form;
        }}
        handleSubmit={this.handleSubmit}
      >
        <FormMessageOverlay
          handleClose={this.handleCloseOverlay}
          isVisible={this.state.formState.showOverlay}
          type={this.state.formState.errorSending ? 'error' : 'success'}
        />
        <FormHeader title={title} subtitle={subtitle} />
        <FormBody>
          {showVariantImage && (
            <div className="conversion-form__photo">
              <img
                src={activeVersion.photo}
                alt={activeVersion.name}
                width="145"
                height="100"
              />
            </div>
          )}
          {this.props.showPrice &&
            showActiveVersionPrice &&
            activeVersion &&
            activeVersion.price && (
              <p className="conversion-form__version-price">
                A partir de{' '}
                <span className="conversion-form__price">
                  {activeVersion.price}
                </span>
              </p>
            )}
          <FieldGroup>
            {showSelectVariant && versions && (
              <Select
                name="selectedVariant"
                options={this.versionsToSelect()}
                handleChange={this.handleVersionChange}
                value={this.selectedVariant}
              />
            )}
            <Input
              title="Nome"
              name="name"
              value={this.state.name}
              type="text"
              placeholder="Nome"
              handleChange={this.handleInputChange}
              required
            />
            <Input
              title="E-mail"
              name="email"
              value={this.state.email}
              type="email"
              placeholder="E-mail"
              handleChange={this.handleInputChange}
              required
            />
            <Input
              title="Telefone"
              name="phone"
              value={this.state.phone}
              type="phone"
              autoComplete="off"
              placeholder="Telefone/Whatsapp"
              handleChange={this.handleInputChange}
              setRef={phoneInput => {
                this.phoneInput = phoneInput;
              }}
              required
            />
            {showCpf && (
              <Input
                title="CPF"
                name="cpf"
                value={this.state.cpf}
                type="text"
                placeholder="CPF"
                handleChange={this.handleInputChange}
                setRef={cpfInput => {
                  this.cpfInput = cpfInput;
                }}
                required
                minLength={14}
              />
            )}
            {showUnits && (
              <Select
                name="unit"
                options={this.props.units}
                label="Escolha a unidade:"
                placeholder="Selecione uma unidade"
                handleChange={this.handleInputChange}
              />
            )}
          </FieldGroup>

          {showVehicleInExchange && (
            <VehicleInExchangeFieldGroup
              handleChange={this.handleInputChange}
            />
          )}

          {showContactOptions && (
            <ContactFieldGroup
              handleChange={this.handleInputChange}
              mailing={this.state.mailing}
              whatsapping={this.state.whatsapping}
              phoning={this.state.phoning}
            />
          )}
          {showDataPermissions && (
            <FieldGroup>
              <Checkbox
                label={dataPermissionsCustomText}
                name="dataPermissions"
                value={this.state.dataPermissions}
                isChecked={this.state.dataPermissions}
                customClasses="form__field-checkbox-data-permissions"
                handleChange={this.handleInputChange}
              />
            </FieldGroup>
          )}
          <FormFooter linkPrivacyPolicy={linkPrivacyPolicy} />
          <SubmitButton
            label={buttonLabel}
            isSubmitting={this.state.formState.isSubmitting}
          />
        </FormBody>
      </FormWrapper>
    );
  }
}

NewVehicleFormApp.defaultProps = {
  title: 'Estou Interessado',
  subtitle:
    'Preencha o formulário abaixo para receber o contato de um de nossos especialistas:',
  buttonLabel: 'Estou interessado',
  defaultVariant: null,
  showActiveVersionPrice: true,
  versions: [],
  showVehicleInExchange: true,
  asynchronouslyLoaded: false,
  basedOnStore: false,
};

NewVehicleFormApp.propTypes = {
  formId: PropTypes.string,
  cloneId: PropTypes.string,
  linkPrivacyPolicy: PropTypes.string,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  showSelectVariant: PropTypes.bool,
  showVariantImage: PropTypes.bool,
  showPrice: PropTypes.bool,
  modelYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCpf: PropTypes.bool,
  showUnits: PropTypes.bool,
  showDataPermissions: PropTypes.bool,
  showContactOptions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  buttonLabel: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  defaultVariant: PropTypes.shape({}),
  showActiveVersionPrice: PropTypes.bool,
  showVehicleInExchange: PropTypes.bool,
  asynchronouslyLoaded: PropTypes.bool,
  basedOnStore: PropTypes.bool,
  productCode: PropTypes.string,
  fcaOfferId: PropTypes.number,
};
